<template>
  <div>
    <div class="mainCont">
      <div class="def-container bannerTxt">
        <span class="chTxt">提出诉求</span><br />
        <span class="enTxt">PROPOSE APPEAL</span>
      </div>
    </div>
    <div class="def-container appeal">
      <div class="title">
        诉求建议范围：
        <i class="icon"></i>
      </div>
      <div class="info">
        对政府职能部门在服务行业，提高办事效率、提高服务质量、开展日常监督、落实政策发布、解读等方面的诉求建议。
      </div>
      <el-form ref="form" :model="appealForm">
        <el-row>
          <el-col :span="1">
            <el-button type="primary">部门选择</el-button>
          </el-col>
          <el-col :span="23">
            <el-form-item>
              <el-cascader
                style="width: 95%; margin-left: 5%"
                :options="optionsDept"
                filterable
                clearable
                v-model="appealForm.deptCode"
                :show-all-levels="false"
                @change="deptChange"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-input
            type="textarea"
            maxlength="500"
            rows="10"
            show-word-limit
            placeholder="诉求建议内容：500字以内。"
            v-model="appealForm.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-radio-group v-model="appealForm.isAnonymous">
            <el-radio label="1">匿名</el-radio>
            <el-radio label="0">实名</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" style="width: 200px" @click="onSubmit"
            >立即提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {},
  data() {
    return {
      optionsDept: [],
      deptArr:{},
      appealForm: {
        deptCode: "",
        content: "",
        isAnonymous: "",
      },
      deptName: "",
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
  },
  created() {
    this.getDept();
  },
  mounted() {},
  methods: {
    // 遍历树形结构
    readNodes(nodes = [], arr = []) {
      for (let item of nodes) {
        arr.push({
          id: item.id,
          label: item.label,
          value: item.value,
        });
        if (item.children && item.children.length)
          this.readNodes(item.children, arr);
      }
      return arr;
    },
    deptChange(val) {
      let that = this;
      that.deptArr.forEach(item=>{
        if(val[1]==item.value){
          that.deptName = item.label; 
        }
      })
    },
    onSubmit() {
      let url = "/dev-api/sqDispose";
      let params = this.appealForm;
      params.deptCode = this.appealForm.deptCode[1];
      params.deptName = this.deptName;
      params.userName = this.userInfo.enterpriseInfo.name;
      params.company = this.userInfo.enterpriseInfo.enterprise;
      params.tyshxydm = this.userInfo.enterpriseInfo.idcard;
      let that = this;
      that.$httpApi.post(url, params).then((res) => {
        if (res.code == 200) {
          that.$message.success("诉求提交成功！");
          that.appealForm.deptCode = [];
          that.appealForm.content = "";
          that.appealForm.isAnonymous = "";
        }
      });
    },
    getDept() {
      let url = "/dev-api/web/dept/treeCascader";
      this.$httpApi.get(url, {}).then((res) => {
        this.optionsDept = res.data;
        this.deptArr = this.readNodes(res.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
.mainCont {
  width: 100%;
  height: 200px;
  background: url("~@/assets/deyangImg/jztuisong.png") no-repeat center / cover;
  display: flex;
  align-items: center;
  .bannerTxt {
    color: #fff !important;
    background: transparent;
    .chTxt {
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 5px;
      margin-left: 65px;
    }
    .enTxt {
      font-size: 22px;
      font-weight: 500;
      word-spacing: 5px;
      letter-spacing: 2px;
      margin-left: 65px;
    }
  }
}
.appeal {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  .title {
    margin-bottom: 20px;
  }
  .info {
    margin-bottom: 10px;
  }
  .icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url("~@/assets/images/appeal.png");
    background-size: contain;
    position: absolute;
    top: -5px;
    left: -20px;
  }
}
</style>
